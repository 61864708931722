<template>
  <div class="error404">
    <h1>Error 404: Page not found</h1>
  </div>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>
